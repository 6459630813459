@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	/* src: url(s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlEA.ttf) format('truetype'); */
  }
  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	/* src: url(s/poppins/v20/pxiEyp8kv8JHgFVrJJfedw.ttf) format('truetype'); */
  }
  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	/* src: url(s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf) format('truetype'); */
  }
  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	/* src: url(s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlEA.ttf) format('truetype'); */
  }
  @font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	/* src: url(s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf) format('truetype'); */
  }
  